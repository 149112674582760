import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    Button, Confirm, Form, Icon, Modal, Popup, Dimmer, Loader,
} from 'semantic-ui-react';

import {
    addDocumentRequest,
    deleteDocumentRequest,
    downloadDocumentRequest,
    editDocumentRequest,
} from '../../ducks/documents';
import DocView from './DocView';
import WebCamUploader from './WebCamUploader';
import FileUploader from './FileUploader';
import './style.scss';

const DocWithEditor = ({
    okButtonText,
    titleText,
    document: currentDocument,
    gridName,
    cardId,
    getDocuments,
    isEditPermissions,
    updateHistory,
    typeId = null,
    isAuth = true,
    isBtn = false,
    uploadFile,
    types,
    id,
    btnIcon,
    btnText,
    btnDisabledPopupText,
    btnPopupText,
    btnPopupPosition,
    offGetTypes = false,
    formats
}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [confirmation, setConfirmation] = useState({ open: false });
    const [document, setDocument] = useState(
        currentDocument ? ({ ...currentDocument }) : null,
    );

    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        setDocument(currentDocument ? ({ ...currentDocument }) : null);
    }, [currentDocument]);

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        getDocuments();
        setDocument(null);
        setModalOpen(false);
    };

    const addDoc = uploadFile || addDocumentRequest;

    const handleSave = () => {
        if (document && document.fileId) {
            const validName = document.name && !!document.name.trim().lastIndexOf('.');

            if (validName) {
                setIsSaving(true);

                document.id
                    ? dispatch(
                        editDocumentRequest({
                            noAuth: !isAuth,
                            gridName,
                            cardId,
                            document: {
                                id: document.id,
                                name: document.name.trim(),
                                fileId: document.fileId,
                                typeId: document.typeId,
                            },
                            id: document.id,
                            callbackSuccess: () => {
                                setIsSaving(false);
                                handleClose();
                                updateHistory && updateHistory();
                            },
                        }),
                    )
                    : dispatch(
                        addDoc({
                            id,
                            noAuth: !isAuth,
                            gridName,
                            cardId,
                            document: {
                                name: document.name.trim(),
                                fileId: document.fileId,
                                typeId: typeId || null,
                            },
                            callbackSuccess: () => {
                                setIsSaving(false);
                                handleClose();
                                updateHistory && updateHistory();
                                setDocument(null);
                            },
                        }),
                    );
            } else {
                toast.error(t('fileNameIsRequired'));
            }
        }
    };

    const handleDownload = () => {
        dispatch(downloadDocumentRequest({ id: document.fileId }));
    };

    const handleDelete = (document) => {
        setConfirmation({
            open: true,
            content: t('Delete document', { name: document.name }),
            cancel: () => {
                setConfirmation({ open: false });
            },
            confirm: () => {
                dispatch(
                    deleteDocumentRequest({
                        noAuth: !isAuth,
                        gridName,
                        cardId,
                        id: document.id,
                        callbackSuccess: () => {
                            getDocuments();
                            updateHistory && updateHistory();
                            setConfirmation({ open: false });
                        },
                    }),
                );
            },
        });
    };

    const addFile = (id, name) => {
        if (id) {
            setDocument({
                ...document,
                fileId: id,
                name,
                typeId: typeId || null,
            });
        }
    };

    const getFileName = () => {
        if (!document || !document.name) return '';
        const { name } = document;
        return name.lastIndexOf('.') !== -1 ? name.substr(0, name.lastIndexOf('.')) : '';
    };

    const saveFileName = (e, { value }) => {
        if (!document || !document.name || document.name.lastIndexOf('.') === -1) return;
        const currentName = document.name;
        const extension = currentName.substr(currentName.lastIndexOf('.'));

        setDocument({
            ...document,
            name: value + extension,
        });
    };

    return (
        <DocView document={currentDocument} isBtn={isBtn} offGetTypes={offGetTypes}>
            {currentDocument && !isBtn ? (
                <div className="file-item-actions">
                    {isEditPermissions ? (
                        <Popup
                            content={t('delete')}
                            position="bottom right"
                            trigger={(
                                <Icon
                                    name="times"
                                    className="uploaded-image-delete-button"
                                    onClick={() => handleDelete(currentDocument)}
                                />
                            )}
                        />
                    ) : null}

                    {isEditPermissions ? (
                        <Popup
                            content={t('edit')}
                            position="bottom right"
                            trigger={(
                                <Icon
                                    name="pencil alternate"
                                    className="uploaded-image-edit-button"
                                    onClick={handleOpen}
                                />
                            )}
                        />
                    ) : null}

                    <Popup
                        content={t('download')}
                        position="bottom right"
                        trigger={(
                            <Icon
                                name="download"
                                className="uploaded-image-download-button"
                                onClick={handleDownload}
                            />
                        )}
                    />
                </div>
            ) : (
                !isBtn
                    ? (
                        <div className="uploaded-image-add-button" onClick={handleOpen}>
                            <Icon name="plus" disabled={!isEditPermissions} className="uploaded-image-add-button-icon" />
                        </div>
                    ) : (
                        <Popup
                            open={btnText && false}
                            content={!isEditPermissions ? t(btnDisabledPopupText) : t(btnPopupText)}
                            position={btnPopupPosition || 'bottom left'}
                            trigger={(
                                <span>
                                    <Button icon={btnIcon || 'plus'} disabled={!isEditPermissions} onClick={handleOpen}>{btnText && t(btnText)}</Button>
                                </span>
                            )}
                        />
                    )
            )}

            <Modal
                size="mini"
                open={modalOpen}
                closeOnEscape
                closeOnDimmerClick={false}
                onClose={handleClose}
            >
                <Modal.Header>{titleText}</Modal.Header>
                <Modal.Content>
                    <div className="uploaded-max-size-info">{t('fileMaxSize25Mb')}</div>
                    <Dimmer active={isSaving} inverted className="table-loader">
                        <Loader size="huge">Loading</Loader>
                    </Dimmer>
                    <Form>
                        <FileUploader
                            formats={formats}
                            document={document}
                            onChange={addFile}
                            types={types}
                        />
                        <WebCamUploader onChange={addFile}>
                            <Button size="small" floated="right" icon="photo" content="Webcam" />
                        </WebCamUploader>
                        <Form.Field>
                            <Form.Input
                                label={t('Document name')}
                                name="name"
                                value={getFileName()}
                                onChange={saveFileName}
                            />
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button icon="ban" content={t('CancelButton')} onClick={handleClose} />
                    <Button icon="check" positive content={okButtonText} onClick={handleSave} />
                </Modal.Actions>
            </Modal>

            <Confirm
                open={confirmation.open}
                content={confirmation.content}
                onCancel={confirmation.cancel}
                cancelButton={t('cancelConfirm')}
                onConfirm={confirmation.confirm}
            />
        </DocView>
    );
};

export default DocWithEditor;
