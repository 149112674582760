import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Grid, Radio, Segment } from 'semantic-ui-react'
import DocumentTC from "./document"
import { useDispatch, useSelector } from "react-redux"
import { columnsTypesConfigSelector } from "../../../../ducks/representations"
import { REGISTRATION_TC_GRID } from "../../../../constants/grids"
import FormField from "../formField"
import {
    documentsTemplatesSelector,
    downloadDocumentRequest,
    getDocumentTypesRequest,
    getTemplatesDocuments
} from "../../../../ducks/documents"
import { configRegTCSelector } from "../../../../ducks/gridCard"
import { getTemplatesListRequest, templateSelector } from "../../../../ducks/reports"
import { SETTINGS_TYPE_EDIT } from "../../../../constants/formTypes"
import {REG_TC_FILE_FORMATS} from "../../../../constants/settings";

const DocumentsRegTC = ({ form = {}, onChange, settings = {}, error, updateHistory, isRegTC }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    useEffect(() => {
        if (!form.id)
            return
            
        dispatch(getTemplatesDocuments({ id: form.id, grid: REGISTRATION_TC_GRID, isRegTC }))
        dispatch(getDocumentTypesRequest())
    }, [form.id])

    const companyOPF = form.companyOPF ? form.companyOPF.value : ''

    useEffect(() => {
        companyOPF && dispatch(getTemplatesListRequest({ opf: companyOPF }))
    }, [companyOPF])

    const documents = useSelector(state => documentsTemplatesSelector(state, companyOPF))
    const urls = useSelector(state => templateSelector(state))

    const columnsConfig = useSelector(state => !isRegTC ? columnsTypesConfigSelector(state, REGISTRATION_TC_GRID) : configRegTCSelector(state))

    const handleDownload = id => {
        dispatch(downloadDocumentRequest({ id }))
    }

    return (
        <Form className="tabs-card">
            <Grid>
                {
                    urls.map(row => (
                        <Grid.Row columns={2} key={row.reportName}>
                            <Grid.Column width="8">
                                <Form.Group inline>
                                    <div>{t(row.reportName)} &emsp;</div>
                                    {row.previousGenericReportTemplateId && <a className='docs-download_link' onClick={() => handleDownload(row.previousGenericReportTemplateId)}>{t('download')}</a>}
                                </Form.Group>
                            </Grid.Column>
                        </Grid.Row>
                    ))
                }
                <Grid.Row columns={2}>
                    <Grid.Column width="6">
                        <FormField
                            name="isAccountingBalance"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                    <Grid.Column width="6">
                        <FormField
                            name="usedElectronicDocumentManagement"
                            columnsConfig={columnsConfig}
                            settings={settings}
                            form={form}
                            error={error}
                            onChange={onChange}
                        />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                    {documents.map(doc => (
                        <DocumentTC
                            formats={REG_TC_FILE_FORMATS}
                            handleDownload={handleDownload}
                            typeId={doc.typeId}
                            id={form.id}
                            fileId={doc.fileId}
                            label={doc.name}
                            text={doc.description}
                            img={doc.file !== '' ? doc.file : null}
                            gridName={REGISTRATION_TC_GRID}
                            updateHistory={updateHistory}
                            disabled={!(settings && settings['documents'] === SETTINGS_TYPE_EDIT)}
                        />
                    ))}
                </Grid.Row>
            </Grid>
        </Form>
    )
}

export default DocumentsRegTC
