import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Image, Segment } from 'semantic-ui-react';
import Documents from '../shared/documents';

const DocumentTC = ({
    label, text, img, gridName, id, fileId, typeId, updateHistory, disabled, handleDownload, getDocs, isBtn, btnText, formats
}) => {
    const { t } = useTranslation();

    return (
        <Grid.Column className="margin-bottom-10" width={8}>
            <Segment>
                <label>{t(label)}</label>

                <div className="margin-bottom-10 margin-top-8">{t(text)}</div>

                {img && !fileId && <Image bordered src={img ? `data:image/jpeg;base64,${img}` : null} />}

                {fileId && <a className="docs-download_link" onClick={() => handleDownload(fileId)}>{t('downloadTemplate')}</a>}

                <Documents
                    formats={formats}
                    isBtn={isBtn}
                    btnText={btnText}
                    getDocs={getDocs}
                    gridName={gridName}
                    cardId={id}
                    typeId={typeId}
                    isEditPermissions={!disabled}
                    updateHistory={updateHistory}
                />
            </Segment>
        </Grid.Column>
    );
};

export default DocumentTC;
